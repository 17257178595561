<template>
  <div class="container-fluid item">
    <div class="img">
      <img
        class="img-fluid"
        src="@/assets/Images/Coaches/Icon awesome-check.png"
        alt="check-img"
        v-if="img"
      />
      <i class="fas fa-circle" v-else></i>
    </div>
    <div class="text">
      <p>
        {{ text }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    img: Boolean,
  },
};
</script>

<style scoped>
.item {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 30px #8f8f8f26;
  border: 1px solid #d4d4d4;
  border-radius: 15px;
  opacity: 1;
  display: inline-flex;
  align-items: center;
  padding: 50px 46px;
  margin-bottom: 40px;
}

.img {
  margin-right: 60px;
}

.img img {
  object-fit: cover;
  max-width: none;
}

.text p {
  text-align: left;
  font: normal normal 500 22px/32px Montserrat;
  letter-spacing: 0px;
  color: #0f1010;
  opacity: 1;
  margin-bottom: 0 !important;
}

.fa-circle {
  color: #FC202E;
}
</style>
